
import mixBannerBase from '@/pages/mixins/mix.banner.base'

export default {
  mixins: [mixBannerBase],
  data () {
    return {
    }
  },
  created () {
    this.navSelf.text = '活动'
    this.navSelf.href = '/activity'
  },
  methods: {
    onClick (ev) {
      const item = ev.item || {}
      const link = item.link || ''
      if (link) {
        window.open(link, '_blank')
      }
    },
  }
}
