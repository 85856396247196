
import api from '@/api/co.api'
import web from '@/api/web/co.web'
import dayjs from 'dayjs'

import mixPreset from '@/pages/mixins/activity/mix.activity.preset'

const parts = mixPreset.Parts
const names = mixPreset.Names

export default {
  data () {
    return {
      currDate: '',
      currTab: names.PROCESSING,
      tabList: [
        parts[names.PROCESSING],
        parts[names.PROCESSED]
      ],
      curPage: 1,
      pag: {
        current: 1,
        length: 1
      },
      tab: parts[names.PROCESSING],
    }
  },
  created () {
    this.currDate = dayjs().format('YYYY-MM-DD')
    const pt0 = this.tabList[0]
    const pt1 = this.tabList[1]

    pt0.doingDate = this.currDate
    pt0.finishDate = ''

    pt1.doingDate = ''
    pt1.finishDate = this.currDate
  },
  methods: {
    changePagination (number, cat) {
      const params = cat.params
      const data = cat.params.data
      const index = parseInt(number, 10) || 1

      cat.pagination.current = index
      data.currentPage = index
      params.reload = true
      api.httpx.getItems(params)
    },
    onContent (ev, navigation) {
      const item = ev.item
      this.toContent(item, navigation)
    },
    toContent (item, navigation) {
      const params = {
        activityId: item.activityId,
        // title: item.title,
        // name: navigation.name,
      }
      api.page.navigateBy(this, 'activityDetail', params)
    },
    configContent (obj) {
      const executed = function (res) {
        // console.log('me.dataList: %o', me.dataList)
        const data = obj.params.data
        const pagination = obj.pagination
        const pageSize = parseInt(data.pageSize) || 9
        pagination.length = Math.ceil(res.total / pageSize)
      }

      obj.params = web.activity.getParams({
        type: obj.type,
        state: obj.state,
        doingDate: obj.doingDate,
        finishDate: obj.finishDate,
        sort: 'sort',
        caches: obj.items,
        executed
      })
    },
    configContents () {
      for (const i in this.tabList) {
        const item = this.tabList[i]
        this.configContent(item)
      }
    },
    loadContents (reload = true) {
      for (const i in this.tabList) {
        const item = this.tabList[i]
        item.params.reload = reload
        api.httpx.getItems(item.params)
      }
    }
  }
}
