<template>
  <section>
    <land-banner
      :items="banItems"
      :height="classify.height"
      :points="classify.points"
      :cycle="classify.cycle"
      :interval="classify.interval"
      :hide-delimiters="wdHideDelimiters"
      :show-arrows="wdShowArrows"
      @click="onClick"
    />
    <land-section
      id="hotcenter"
      space="40"
    >
      <v-container
        style="max-width: 1200px;"
      >
        <v-card
          class="mb-6"
          color="transparent"
          flat
          tile
        >
          <v-tabs
            v-model="currTab"
            background-color="transparent"
            class="d-flex flex-row justify-center align-center"
          >
            <v-tab
              v-for="(tab, tabIndex) in tabList"
              :key="tabIndex"
              :value="tab.name"
              :href="`#${tab.name}`"
              class="text-h6"
            >
              {{ tab.title }}
            </v-tab>
          </v-tabs>
        </v-card>
        <v-row
          justify="center"
        >
          <v-col
            cols="12"
          >
            <v-tabs-items v-model="currTab">
              <v-tab-item
                v-for="(tab, tabIndex) in tabList"
                :key="tabIndex"
                :value="tab.name"
                class="rounded grey lighten-4"
              >
                <land-gallery
                  v-if="tab.items.length"
                  :items="tab.items"
                  :type="tab.type"
                  :ratio="tab.ratio"
                  :tooled="tab.tooled"
                  :margin="tab.margin"
                  @click="onContent($event, tab)"
                />
                <div
                  v-else
                  class="co-flex-col co-justify-center co-items-center pa-12"
                >
                  <span
                    class="co-text-md co-text-gray-500"
                  >
                    {{ tab.empty }}
                  </span>
                </div>

                <div
                  v-if="tab.pagination.length"
                  class="d-flex flex-row mx-5 mt-4 mb-8"
                >
                  <v-pagination
                    v-model="tab.pagination.current"
                    :length="tab.pagination.length"
                    :total-visible="tab.pagination.totalVisible"
                    @input="changePagination($event, tab)"
                  />
                </div>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
    </land-section>

    <!-- <section-foot /> -->
  </section>
</template>

<script>
  import web from '@/api/web/co.web'
  import mixActivityBanner from '@/pages/mixins/activity/mix.activity.banner'
  import mixActivityBoot from '@/pages/mixins/activity/mix.activity.boot'

  export default {
    metaInfo: { title: '活动' },
    components: {
      // SectionFoot: () => import('@/pages/sections/Foot.vue')
    },
    mixins: [
      mixActivityBanner,
      mixActivityBoot
    ],
    data () {
      return {
      }
    },
    created () {
      this.initBannerParams({
        type: web.comm.BannerTypes.ACTIVITY_MAJOR
      })
      this.loadBanners()

      this.configContents()
      this.loadContents()
    },
    methods: {
    }
  }
</script>
